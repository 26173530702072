.words-queue {
      position: absolute;
      right: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.7);
      max-height: 100%;
      overflow-y: auto;
      padding: 10px;
      min-height: 100vh;
      animation: zoom-in 0.22s ease-in-out;
}

@keyframes zoom-in {
      from {
            transform: scale(0);
      }
      to {
            transform: scale(1);
      }
}

.words-queue-theme-2 {
      background-color: #5fdc72;
}

.words-queue-blud-theme {
      background-color: #5facdc;
}

.word-stats {
      flex-direction: column;
}
