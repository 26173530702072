.settings-form {
      display: flex;
      align-items: center;
      width: 95vw;
      max-width: 700px;
      transition: opacity 0.25s linear;
      opacity: 1;
}

.filtering-section {
      margin: 10px;
      display: flex;
      background-color: brown;
      border-radius: 20px;
      justify-content: space-between;
      overflow-x: scroll;
      scroll-behavior: smooth;
}

.filtering-section::-webkit-scrollbar {
      display: none;
}

.select {
      font-weight: bold;
      font-size: 13px;
      background-color: transparent;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 20px;
      color: black;
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 3px;
      margin-bottom: 4px;
      border: 2px solid black;
      cursor: pointer;
      transition: transform linear 0.1s;
      font-family: inherit;
      max-width: 150px;
}
.select:hover {
      border: 2px solid white;
}

option {
      margin: 5px;
}

.filtering-section-theme-2 {
      background-color: #5fdc72;
}
.filtering-section-theme-1 {
      background-color: #5facdc;
}
.filtering-section-theme-3 {
      background-color: #c280f1;
}

.filtering-section-theme-4 {
      background-color: rgba(0, 255, 0, 0.5);
}
.filtering-section-theme-5 {
      background-color: rgba(255, 0, 0, 0.6);
}
.filtering-section-theme-6 {
      background-color: rgba(255, 255, 0, 0.5);
}
.filtering-section-theme-7 {
      background-color: rgba(0, 255, 255, 0.4);
}

@media (max-width: 450px) {
      .select {
            font-size: 11px;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 2px;
            padding-bottom: 2px;
      }
}
