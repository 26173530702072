.nav-item-name {
      color: black;

      position: absolute;

      bottom: -35px;
      right: 0;
      z-index: 10000;
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 5px;
      font-size: 0.8em;
      animation: zoom-in 0.15s ease-in-out;
}

.nav-item-name-theme-2 {
      background-color: #5fdc72;
}

.nav-item-name-theme-1 {
      background-color: #5facdc;
}

.nav-item-name-theme-3 {
      background-color: #c280f1;
}

@keyframes zoom-in {
      from {
            transform: scale(0);
      }
      to {
            transform: scale(1);
      }
}
