.page {
      display: flex;
      flex-direction: column;
      line-height: 1.5;
      color: #333;
      width: 100vw;
      align-items: center;
      min-height: 100vh;
      background-color: #1a866b;
      font-family: monospace;
      font-size: 16px;
}
.main {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      width: 100%;
}

.register-form {
      background-color: #5fdc72;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      width: 90%;
      max-width: 400px;
      height: 400px;

      justify-content: space-around;
      color: white;
      align-items: center;
}

.label {
      border-radius: 5px;
}

.user-details-input {
      background-color: #1a866b;
      color: white;
      width: 70%;
      border-radius: 20px;
      padding-left: 10px;
}
::placeholder {
      color: white;
}
.register-button {
      background-color: #1a866b;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      color: white;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 20px;
}
.login-button:hover {
      box-shadow: 0 0 10px rgba(0, 255, 0, 1);
}

@media (max-width: 700px) {
      .page {
            font-size: 11px;
      }
}
