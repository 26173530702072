.word {
      background-color: #5fdc72;
      padding: 10px;
      box-shadow: 5px 3px 3px;
      margin: 5px;
      transition: opacity 0.25s linear;
      position: relative;
      border-radius: 10px;
}

.container {
      position: absolute;
      right: 10px;
      top: 10px;
      color: black;
      display: flex;
}

.words-queue-open-button {
      font-size: 0.75em;
      background-color: transparent;
      padding-left: 3px;
      padding-right: 3px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 5px;
      border: 2px solid black;
      cursor: pointer;
      margin-right: 15px;
}

.words-queue-open-button:hover {
      border: 2px solid white;
}

.word-title {
      color: black;
      font-size: 1.3em;
}
.word-stats {
      font-family: monospace;
      color: black;
      display: flex;
      overflow-x: scroll;
}

.word-stats::-webkit-scrollbar {
      display: none;
}

.word-theme-2 {
      background-color: #5fdc72;
}

.word-theme-1 {
      background-color: #5facdc;
}

.word-theme-3 {
      background-color: #c280f1;
}
.word-theme-4 {
      background-color: rgba(0, 255, 0, 0.4);
      box-shadow: none;
}
.word-theme-5 {
      background-color: rgba(255, 0, 0, 0.5);
      box-shadow: none;
}
.word-theme-6 {
      background-color: rgba(255, 255, 0, 0.4);
      box-shadow: none;
}
.word-theme-7 {
      background-color: rgba(0, 255, 255, 0.3);
}
.table {
      border-collapse: collapse;
      margin: 5px;
      font-size: 0.85em;
}
.th,
.td {
      border: 2px solid black;
      padding: 5px;
      text-align: center;
      min-width: 100px;
}
