.card-two-theme-1 {
      background-color: #5facdc;
}
.card-two-theme-2 {
      background-color: #5fdc72;
}

.card-two-theme-3 {
      background-color: #c280f1;
}
.card-two-theme-4 {
      background-color: rgba(0, 255, 0, 0.4);
}
.card-two-theme-5 {
      background-color: rgba(255, 0, 0, 0.6);
}
.card-two-theme-6 {
      background-color: rgba(255, 255, 0, 0.3);
}
.card-two-theme-7 {
      background-color: rgba(0, 255, 255, 0.3);
}
