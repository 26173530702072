.nav-bar {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      position: relative;
}
.logo {
      margin: 10px;
      letter-spacing: 5px;
      color: white;
      font-style: italic;
      box-shadow: 2px 2px 2px black;
      border-radius: 5px;
      font-size: 18px;
      padding-left: 5px;
}

.search-bar {
      width: 300px;
      height: 30px;
      border-radius: 15px;
      outline: none;
}

.nav-items {
      display: flex;
      align-items: center;
      margin-right: 5px;
}
.nav-link {
      text-decoration: none;
      color: white;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
}

.logout-form {
      display: inline;
}

.logout-button {
      background-color: transparent;
      outline: none;
      cursor: pointer;
      font-size: inherit;
      color: white;
      font-family: inherit;
}

@media (max-width: 450px) {
      .logo {
            font-size: 13px;
            margin-left: 5px;
      }
}
