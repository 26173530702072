.page {
      background-color: "black";
      background-color: #1a866b;
      color: #333;
      font-family: monospace;
      font-size: 16px;
      height: 100svh;
      line-height: 1.5;
      display: flex;
      flex-direction: column;
}

.main {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
}

@media (max-width: 700px) {
      .page {
            font-size: 11px;
      }
}
