.page {
      display: flex;
      flex-direction: column;
      font-family: monospace;
      line-height: 1.5;
      color: #333;
      align-items: center;
      transition: background-color 0.5s linear;
      font-size: 16px;
      height: 100svh;
      overflow-y: scroll;
      overflow-x: hidden;
}

.main {
      padding: 20px;
      width: 75%;
      max-width: 1150px;
}

.guide-heading {
      color: white;
      text-align: center;
}

.heading-theme-2 {
      color: #5fdc72;
}
.heading-theme-1 {
      color: #5facdc;
}
.heading-theme-3 {
      color: #c280f1;
}
.heading-theme-4 {
      color: white;
}
.heading-theme-5 {
      color: white;
}

.heading-theme-6 {
      color: white;
}
.heading-theme-7 {
      color: white;
}

.content {
      color: rgba(255, 255, 255, 0.9);
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);
      padding: 10px;
      margin-bottom: 10px;
      font-size: 0.92em;
}

.content-light-mode {
      color: rgba(0, 0, 0, 0.8);
      border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

.ul {
      padding-left: 15px;
}
.p,
.li {
      margin: 5px;
      margin-top: 10px;
}

.sub-heading {
      color: black;
      margin-top: 10px;
}
.answers {
      margin-left: 20px;
      padding: 5px;
}

@media (max-width: 800px) {
      .main {
            width: 90vw;
            padding: 0;
      }
      /* .heading {
            font-size: 1em;
      }

      .content {
            font-size: 0.8em;
      } */
}

@media (max-width: 700px) {
      .page {
            font-size: 12px;
      }
}
