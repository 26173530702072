.user-information-section {
      position: absolute;
      right: 10px;
      top: 40px;
      padding: 20px;
      border-radius: 10px;
      background-color: #5fdc72;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 1000;
      gap: 10px;
      animation: zoom-in 0.15s ease-in-out;
}

@keyframes zoom-in {
      from {
            transform: scale(0);
      }
      to {
            transform: scale(1);
      }
}

.user-information-section-theme-2 {
      background-color: #5fdc72;
}

.user-information-section-theme-1 {
      background-color: #5facdc;
}

.user-information-section-theme-3 {
      background-color: #c280f1;
}

.user-icon {
      width: 50px;
      margin: 5px;
}

.logout-button {
      font-weight: bold;
      font-size: 13px;
      background-color: transparent;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 5px;
      color: black;
      border: 2px solid black;
      cursor: pointer;
      min-width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
}
.logout-button:hover {
      border: 2px solid white;
}

@media (max-width: 700px) {
      .user-icon {
            width: 30px;
      }
}
