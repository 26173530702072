.page {
      display: flex;
      flex-direction: column;
      font-family: monospace;
      line-height: 1.5;
      color: #333;
      width: 100vw;
      align-items: center;
      font-size: 16px;
      height: 100svh;
      overflow-y: scroll;
      overflow-x: hidden;
}

.loader {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 100000;
}

.main {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;
}

.language-overall-stats-table {
      border-spacing: 0;
      border-collapse: separate;
      border: 2px solid black;
      border-radius: 10px;
      font-size: 0.85em;
      width: 95vw;
      max-width: 1100px;
      color: black;
}

table.language-overall-stats-table th:not(:last-child),
table.language-overall-stats-table td:not(:last-child) {
      border-right: 2px solid black;
}

table.language-overall-stats-table > tr:not(:last-child) > td,
table.language-overall-stats-table > tr:not(:last-child) > th {
      border-bottom: 2px solid black;
}

.language-overall-stats-table-head,
.language-overall-stats-table-data {
      padding: 5px;
      height: 70px;
}

.language-overall-stats-table-head {
      max-width: 150px;
}

.language-filter-section {
      display: flex;
      margin: 10px;
      margin-top: 30px;
      justify-content: center;
      width: fit-content;
      border-radius: 20px;
}

.language-filter {
      background-color: #c280f1;
      font-style: italic;
      font-weight: bold;
      font-size: 13px;
      background-color: transparent;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 20px;
      color: black;
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 3px;
      margin-bottom: 4px;
      border: 2px solid black;
      cursor: pointer;
      transition: transform linear 0.1s;
      font-family: inherit;
      max-width: 220px;
}

.language-filter:hover {
      border: 2px solid white;
}

.all-time-stats-section {
      display: grid;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      margin: 10px;
      width: 70%;
}

.all-time-stat {
      height: 150px;
      color: black;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      /* border-radius: 10px; */
      text-align: center;
      padding: 10px;
      box-shadow: 3px 3px 3px black;
      transition: transform 0.15s linear;
}
.all-time-stat:hover {
      transform: scale(0.95);
      cursor: pointer;
}

.all-time-stat.theme-1 {
      background-color: #5facdc;
}
.all-time-stat.theme-2 {
      background-color: #5fdc72;
}
.all-time-stat.theme-3 {
      background-color: #c280f1;
}
.all-time-stat.theme-4 {
      background-color: rgba(0, 255, 0, 0.4);
}

.characters-bar-graph-section {
      height: 600px;
      width: 90vw;
      max-width: 700px;

      padding: 10px;
}

.language-sub-type-stats {
      width: 95vw;
      max-width: 1100px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
}

.last-ten-tests-section {
      width: 95vw;
      max-width: 1100px;
      color: white;
      text-align: center;
      margin-top: 30px;
}
.last-ten-tests-table {
      margin-top: 20px;
      color: black;
      width: 100%;
      text-align: center;
      font-size: 0.85em;
      border-spacing: 0;
      border-collapse: separate;
      border: 2px solid black;
      border-radius: 10px;
}
table.last-ten-tests-table th:not(:last-child),
table.last-ten-tests-table td:not(:last-child) {
      border-right: 2px solid black;
}

table.last-ten-tests-table > tbody:not(:last-child) > tr > th,
table.last-ten-tests-table > tbody:not(:last-child) > tr > td {
      border-bottom: 2px solid black;
}

.words-section,
.letters-section {
      display: grid;
      grid-row-gap: 10px;
      grid-column-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(min-content, 1fr));
      margin: 10px;
}

.word,
.letter {
      background-color: #5fdc72;
      padding: 10px;
      box-shadow: 5px 3px 3px gray;
      transition: transform 0.15s linear;
}

.lettere:hover,
.word:hover {
      transform: scale(0.95);
      cursor: pointer;
}

.word-title,
.letter-title {
      font-size: 24px;
      color: black;
}
.word-stats,
.letter-stats {
      font-size: 14px;
      font-family: monospace;
      color: #333;
}

.word-mode {
      color: gray;
}
h1 {
      color: white;
}

.th,
.td {
      padding: 5px;
      text-align: center;
}

@media (max-width: 800px) {
      .page {
            font-size: 12px;
      }
      .th,
      .td {
            padding: 2px;
      }
      .language-filter {
            padding-left: 6px;
            padding-right: 6px;
            padding-top: 2px;
            padding-bottom: 2px;
      }
}
@media (max-width: 450px) {
      ::-webkit-scrollbar {
            width: 0;
      }
}
