.beginner-mode-information {
      display: flex;
      gap: 10px;
      width: 99vw;
      max-width: fit-content;
      width: 100%;
      color: black;
}

.information-one {
      background-color: #5fdc72;
      padding: 10px;
      box-shadow: 5px 3px 3px;
      transition: opacity 0.25s linear;
      margin: 5px;
      position: relative;
      opacity: 1;
      border-radius: 10px;
}

.information-one-theme-2,
.information-two-theme-2 {
      background-color: #5fdc72;
}

.information-one-theme-1,
.information-two-theme-1 {
      background-color: #5facdc;
}

.information-one-theme-3,
.information-two-theme-3 {
      background-color: #c280f1;
}

.information-one-theme-4,
.information-two-theme-4 {
      background-color: rgba(0, 255, 0, 0.4);
}
.information-one-theme-5,
.information-two-theme-5 {
      background-color: rgba(255, 0, 0, 0.5);
}
.information-one-theme-6,
.information-two-theme-6 {
      background-color: rgba(255, 255, 0, 0.4);
}

.information-one-theme-7,
.information-two-theme-7 {
      background-color: rgba(0, 255, 255, 0.4);
}

.information-two {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding: 10px;
      box-shadow: 5px 3px 3px;
      transition: opacity 0.25s linear;
      border-radius: 10px;
      margin: 5px;
}

.current-key {
      position: absolute;
      left: 5px;
      top: 0px;
}

.hand {
      width: 70px;
}
.word-title {
      font-weight: bold;
      font-size: 0.8em;
      display: flex;
      justify-content: space-between;
      gap: 10px;
}

.last-ten-test-open-button {
      right: 15px;
      top: 5px;
      font-family: monospace;
      font-size: 0.8em;
      font-style: italic;
      font-weight: bold;
      background-color: transparent;
      border-radius: 5px;
      border: 2px solid black;
      cursor: pointer;
      min-width: max-content;
      height: fit-content;
}

.last-ten-test-open-button:hover {
      border: 2px solid white;
}
.word-stats {
      font-family: monospace;
      display: flex;
      overflow-x: scroll;
      margin-top: 5px;
}

.word-stats::-webkit-scrollbar {
      display: none;
}

.table {
      border-collapse: collapse;
      margin: 5px;
      font-size: 0.85em;
      width: 100%;
}
.th,
.td {
      border: 2px solid black;
      padding: 5px;
      text-align: center;
      min-width: 100px;
}

@media (max-width: 450px) {
      .hand {
            width: 50px;
      }
      .beginner-mode-information {
            flex-direction: column;
      }
}

@media (max-width: 700px) {
      .beginner-mode-information {
            gap: 0px;
      }
}
