.google-sign-in {
      display: inline-flex;
      gap: 10px;
      align-items: center;
      margin: 10px;
      cursor: pointer;
      background-color: black;
      border-radius: 5px;
      padding-left: 3px;
      padding-right: 3px;
}
