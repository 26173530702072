.test-stats {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
}

.controls {
      display: block;
}

.test-stat {
      color: white;
      font-size: 1.3em;
      padding: 10px;
      font-style: italic;
}

.typing-paragraph {
      max-width: 900px;
      height: 120px;
      color: #e9e0d2;
      font-size: 24px;
      overflow-y: hidden;
      position: relative;
      font-family: Arial, sans-serif;
      scroll-behavior: smooth;
}
.paragraph-on-blur-overlay,
.paragraph-loader {
      position: sticky;
      height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.7);
      font-size: 0.7em;
      padding: 2px;
}

.paragraph-on-blur-overlay {
      text-align: center;
}

.word {
      display: inline-block;
      height: 40px;
      margin-left: 2px;
      margin-right: 2px;
}
.letter {
      border: 0px solid white;
      transition: border ease-out 0.2s;
      transition: color 0.2s linear;
      margin: 1px;
      display: inline-block;
      height: 36px;
}

.text-effect-1 {
      animation: text-effect-1 0.2s linear;
      transform: scale(1);
}

@keyframes text-effect-1 {
      from {
            transform: scale(1);
      }
      to {
            transform: scale(1.3);
      }
}

.text-effect-2 {
      animation: text-effect-2 0.2s linear;
      transform: scale(0);
}

@keyframes text-effect-2 {
      from {
            transform: scale(1);
      }
      to {
            transform: scale(0);
      }
}

.text-effect-3 {
      animation: text-effect-3 0.2s linear;
      transform: scale(0);
}

@keyframes text-effect-3 {
      0% {
            transform: scale(1);
      }
      50% {
            transform: scale(1.5);
      }
      100% {
            transform: scale(0);
      }
}

.active-right-theme-2 {
      color: #5fdc72;
}
.active-right-theme-1 {
      color: #5facdc;
}
.active-right-theme-3 {
      color: #c280f1;
}
.active-right-theme-4 {
      color: rgba(0, 255, 0, 0.3);
}
.active-right-theme-5 {
      color: rgba(255, 0, 0, 0.6);
}
.active-right-theme-6 {
      color: rgba(255, 255, 0, 0.4);
}
.active-right-theme-7 {
      color: rgba(0, 255, 255, 0.3);
}
.active-wrong {
      background-color: rgb(240, 68, 68);
}

.active-next-character {
      border-bottom: 2px solid white;
}

.load-next-paragraph-form {
      display: inline-block;
}

.load-next-paragraph-button {
      width: 30px;
      height: 30px;
      font-style: italic;
      font-weight: bold;
      font-size: 0.9em;
      background-color: white;
      border-radius: 59%;
      color: black;
      margin: 5px;
      cursor: pointer;
}

.icon-theme-2 {
      background-color: #5fdc72;
      color: black;
}
.icon-theme-1 {
      background-color: #5facdc;
      color: black;
}

.icon-theme-3 {
      background-color: #c280f1;
      color: black;
}

.icon-theme-4 {
      background-color: rgba(0, 255, 0, 0.4);
      color: black;
}

.icon-theme-5 {
      background-color: rgba(255, 0, 0, 0.6);
      color: black;
}
.icon-theme-6 {
      background-color: rgba(255, 255, 0, 0.4);
      color: black;
}
.icon-theme-7 {
      background-color: rgba(0, 255, 255, 0.3);
      color: black;
}

@media (max-width: 450px) {
      .paragraph-on-blur-overlay {
            font-size: 0.6em;
      }
}

@media (max-width: 700px) {
      .load-next-paragraph-button {
            height: 23px;
            width: 23px;
      }
}
