.card-one-theme-1 {
      background-color: #29779e;
}

.card-one-theme-2 {
      background-color: #1a866b;
}

.card-one-theme-3 {
      background-color: #5d1a86;
}

.card-one-theme-4 {
      background: linear-gradient(rgba(0, 255, 0, 0.3), rgba(0, 0, 0, 0.6));
      background-color: black;
}
.card-one-theme-5 {
      background: linear-gradient(rgba(255, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
      background-color: black;
}
.card-one-theme-6 {
      background: linear-gradient(rgba(255, 255, 0, 0.3), rgba(0, 0, 0, 0.6));
      background-color: black;
}
.card-one-theme-7 {
      background: linear-gradient(rgba(0, 255, 255, 0.4), rgba(0, 0, 0, 0.6));
      background-color: black;
}
