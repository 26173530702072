.word {
      background-color: #5fdc72;
      padding: 10px;
      box-shadow: 5px 3px 3px;
      transition: opacity 0.25s linear;
      margin: 5px;
      position: relative;
      opacity: 1;
      border-radius: 10px;
}

.word-title {
      color: black;
      font-weight: bold;
      font-size: 0.8em;
      display: flex;
      justify-content: space-between;
}

.last-ten-test-open-button {
      right: 15px;
      top: 5px;
      color: black;
      font-family: monospace;
      font-size: 0.8em;
      font-style: italic;
      font-weight: bold;
      background-color: transparent;
      border-radius: 5px;
      color: black;
      border: 2px solid black;
      cursor: pointer;
      min-width: max-content;
      height: fit-content;
}

.last-ten-test-open-button:hover {
      border: 2px solid white;
}
.word-stats {
      font-family: monospace;
      color: black;
      display: flex;
      overflow-x: scroll;
      margin-top: 5px;
}

.word-stats::-webkit-scrollbar {
      display: none;
}

.word-theme-2 {
      background-color: #5fdc72;
}

.word-theme-1 {
      background-color: #5facdc;
}

.word-theme-3 {
      background-color: #c280f1;
}

.word-theme-4 {
      background-color: rgba(0, 255, 0, 0.4);
      box-shadow: none;
}
.word-theme-5 {
      background-color: rgba(255, 0, 0, 0.5);
      box-shadow: none;
}
.word-theme-6 {
      background-color: rgba(255, 255, 0, 0.3);
      box-shadow: none;
}
.word-theme-7 {
      background-color: rgba(0, 255, 255, 0.3);
}
.table {
      border-collapse: collapse;
      margin: 5px;
      font-size: 0.85em;
}
.th,
.td {
      border: 2px solid black;
      padding: 5px;
      text-align: center;
      min-width: 100px;
}
