.word {
      background-color: #5fdc72;
      padding: 10px;
      box-shadow: 5px 3px 3px;
      transition: opacity 0.25s linear;
      position: relative;
      opacity: 1;
      width: 49%;
      border-radius: 10px;
      color: black;
}

.word-title {
      color: black;
}
.word-stats {
      font-family: monospace;
      display: flex;
      overflow-x: scroll;
      width: 100%;
}

.word-stats::-webkit-scrollbar {
      display: none;
}

.word-theme-2 {
      background-color: #5fdc72;
}

.word-theme-1 {
      background-color: #5facdc;
}

.word-theme-3 {
      background-color: #c280f1;
}
.word-theme-4 {
      background-color: rgba(0, 255, 0, 0.4);
}
.word-theme-5 {
      background-color: rgba(255, 0, 0, 0.5);
}
.word-theme-6 {
      background-color: rgba(255, 255, 0, 0.3);
}
.word-theme-7 {
      background-color: rgba(0, 255, 255, 0.3);
}

.table {
      border-collapse: collapse;
      margin: 5px;
      font-size: 0.8em;
      width: 100%;
}
.th,
.td {
      border: 2px solid black;
      padding: 5px;
      text-align: center;
}

@media (max-width: 1000px) {
      .word {
            width: 100%;
      }
}

@media (max-width: 450px) {
      .word {
            padding: 5px;
      }
}
