.closing-button {
      font-size: 15px;
      cursor: pointer;
      color: white;
      background-color: transparent;
      cursor: pointer;
      width: fit-content;
}

.closing-button:hover {
      color: red;
}
