.image-overlay {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      animation: opacity-animation 0.5s linear;
      flex-direction: column;
}

@keyframes opacity-animation {
      from {
            opacity: 0;
      }
      to {
            opacity: 1;
      }
}

.close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 3px;
      color: white;
      background-color: red;
      cursor: pointer;
}
.close-button:hover {
      transform: scale(0.9);
}
.overlay-image {
      width: 700px;
}

.image-controls {
      font-size: 32px;
      display: flex;
      gap: 30px;
      margin-top: 15px;
      color: white;
}

.image-control {
      cursor: pointer;
}
.image-control:hover {
      transform: scale(0.9);
}

@media (max-width: 800px) {
      .overlay-image {
            width: 95vw;
      }
}
