* {
      box-sizing: border-box;
      margin: 0;
      border: none;
      text-decoration: none;
      outline: none;
}
body {
      overflow-x: hidden;
}

::-webkit-scrollbar {
      width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
      background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
      background: gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
      background: #555;
}
