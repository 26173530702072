.data-saving-uploading-section {
      position: absolute;
      padding: 30px;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      /* justify-content: center; */
      z-index: 1000;
      flex-direction: column;
      gap: 30px;
      font-size: 14px;
}

.file-input {
      height: 0.1px;
      width: 0.1px;
}

.upload-label {
      border-radius: 5px;
      background-color: white;
      color: black;
      width: 180px;
      font-family: inherit;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      border: 2px solid transparent;
      display: flex;
      gap: 20px;
      align-items: center;
      padding-left: 6px;
}
.download-button {
      border-radius: 5px;
      background-color: white;
      color: black;
      min-width: 180px;
      font-family: inherit;
      cursor: pointer;
      border: 2px solid transparent;
      display: flex;
      gap: 20px;
      align-items: center;
      padding-left: 6px;
      font-size: inherit;
}

.upload-label-theme-2 {
      background-color: #5fdc72;
}

.upload-label-theme-1 {
      background-color: #5facdc;
}
.upload-label-theme-3 {
      background-color: #c280f1;
}
.upload-label-theme-4 {
      background-color: rgba(0, 255, 0, 0.5);
}
.upload-label-theme-5 {
      background-color: rgba(255, 0, 0, 0.5);
}
.upload-label-theme-6 {
      background-color: rgba(255, 255, 0, 0.4);
}
.upload-label-theme-7 {
      background-color: rgba(0, 255, 255, 0.5);
}

.upload-label:hover,
.download-button:hover {
      border: 2px solid white;
}

@media (max-width: 500px) {
      .data-saving-uploading-section {
            font-size: 12px;
      }
}
