.last-ten-tests-section {
      background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 10px;
      color: white;
      text-align: center;
      overflow-y: auto;
      overflow-x: hidden;
}
.last-ten-tests-table {
      margin-top: 20px;
      color: black;
      width: 100%;
      text-align: center;
      border: 2px solid black;
      border-collapse: collapse;
      margin: 5px;
      font-size: 0.85em;
}

.last-ten-tests-table.theme-2 {
      background-color: #5fdc72;
}

.last-ten-tests-table.theme-1 {
      background-color: #5facdc;
}
.last-ten-tests-table.theme-3 {
      background-color: #c280f1;
}
.last-ten-tests-table.theme-4 {
      background-color: rgba(0, 255, 0, 0.5);
}
.last-ten-tests-table.theme-5 {
      background-color: rgba(255, 0, 0, 0.5);
}
.last-ten-tests-table.theme-6 {
      background-color: rgba(255, 255, 0, 0.4);
}
.last-ten-tests-table.theme-7 {
      background-color: rgba(0, 255, 255, 0.4);
}
.th,
.td {
      border: 2px solid black;
      padding: 5px;
      text-align: center;
}
