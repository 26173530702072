* {
      box-sizing: border-box;
      margin: 0;
      border: none;
      line-height: 1.6;
      text-decoration: none;
}
a {
      font-size: unset;
}
.page {
      display: flex;
      flex-direction: column;
      line-height: 1.5;
      color: #333;
      width: 100vw;
      align-items: center;
      min-height: 100svh;
      background-color: #1a866b;
      font-family: monospace;
      font-size: 16px;
}
.main {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      width: 100%;
}

.login-form-section {
      background-color: #5fdc72;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      width: 90%;
      height: 400px;
      max-width: 400px;
      justify-content: space-around;
      color: white;
      align-items: center;
      padding: 10px;
}

.login-form {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      flex-grow: 1;
}
heading {
      font-size: larger;
}
.label {
      border-radius: 5px;
}

.user-details-input {
      background-color: #1a866b;
      color: white;
      width: 70%;
      border-radius: 20px;
      padding: 2px;
      padding-left: 10px;
      outline: none;
      margin: 50px;
}
.user-details-input::placeholder {
      color: white;
}
.login-button,
.register-button {
      background-color: transparent;
      cursor: pointer;
      color: black;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 15px;
      margin: 5px;
      font-size: 16px;
      width: 50%;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid black;
}
.login-button {
      margin-top: auto;
}
.login-button:hover,
.register-button:hover {
      box-shadow: 0 0 10px rgb(242, 250, 242);
      border: 2px solid white;
}

@media (max-width: 700px) {
      .page {
            font-size: 11px;
      }
}
