.beginner-mode-challenges {
      position: absolute;
      right: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.7);
      max-height: 100%;
      overflow-y: auto;
      padding: 25px;
      padding-left: 30px;
      min-height: 100vh;
      color: black;
      display: flex;
      gap: 12px;
      flex-direction: column;
      animation: zoom-in 0.22s ease-in-out;
}

@keyframes zoom-in {
      from {
            transform: scale(0);
      }
      to {
            transform: scale(1);
      }
}

.beginner-mode-challenges-theme-2 {
      background-color: #5fdc72;
}

.beginner-mode-challenges-blud-theme {
      background-color: #5facdc;
}

.challenges-sub-type {
      color: black;
}

.sub-type-heading {
      color: white;
}

.challenge {
      background-color: #5fdc72;
      height: fit-content;
      padding: 5px;
      border-bottom: 2px solid black;
      cursor: pointer;
      min-width: 250px;
      display: flex;
      gap: 20px;
      transition: transform 0.1s linear;
}

.challenge-theme-2 {
      background-color: #5fdc72;
}

.challenge-theme-1 {
      background-color: #5facdc;
}

.challenge-theme-3 {
      background-color: #c280f1;
}

.challenge-theme-4 {
      background-color: rgba(0, 255, 0, 0.5);
}

.challenge-theme-5 {
      background-color: rgba(255, 0, 0, 0.5);
}

.challenge-theme-6 {
      background-color: rgba(255, 255, 0, 0.4);
}
.challenge-theme-7 {
      background-color: rgba(0, 255, 255, 0.4);
}

.challenge:hover {
      transform: scale(0.97);
}

.lesson-number {
      min-width: 30px;
}

.lesson-name {
      flex-grow: 1;
}
