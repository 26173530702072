.page {
      display: flex;
      flex-direction: column;
      height: 100svh;
      font-family: monospace;
      align-items: center;
      justify-content: center;
      background-color: #1a866b;
      transition: background-color 0.5s linear;
      font-size: 16px;
}
