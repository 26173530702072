.page {
      display: flex;
      flex-direction: column;
      height: 100svh;
      font-family: monospace;
      line-height: 1.5;
      color: #333;
      align-items: center;
      justify-content: center;
      transition: background-color 0.5s linear;
      font-size: 16px;
}

.main {
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
}

.loading-text {
      color: white;
      font-size: inherit;
}

.language-stats-section {
      max-width: fit-content;
      max-width: 95vw;
}

.practise-word-section {
      width: 95vw;
      max-width: fit-content;
}

.typing-section {
      margin: auto;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
}

.practise-words {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      overflow: auto;
      padding: 10px;
      background-color: aliceblue;
}

@media (max-width: 700px) {
      .page {
            font-size: 11px;
      }
}
